<template>
  <div class="scanCheckBox">
    <div class="scan-check-header hidden-div">
      <div>
        异常原因：
        <template v-if="GET_INDEX_SCAN_TYPE == 2"
          >图像可能存在残缺或折角。</template
        >
        <template v-if="GET_INDEX_SCAN_TYPE == 3"
          >定位信息印刷残缺或扫错答卷。</template
        >
        <template v-if="GET_INDEX_SCAN_TYPE == 5">
          系统自动检测答题卡标记信息，当与模板标记信息不一致时出现学科异常。</template
        >
        <template v-if="GET_INDEX_SCAN_TYPE == 15">
          系统自动检测答题卡标记信息，当与模板标记信息不一致时出现标题异常。</template
        >
        <template v-if="GET_INDEX_SCAN_TYPE == 6">
          考号无法识别或不在计划考生列表内。</template
        >
        <template v-if="GET_INDEX_SCAN_TYPE == 7">
          涂错考号或重复扫描。</template
        >
        <template v-if="GET_INDEX_SCAN_TYPE == 13">
          系统识别缺考标记被填涂。</template
        >
        <template v-if="GET_INDEX_SCAN_TYPE == 11">
          系统识别A-B卷失败。</template
        >
        <el-button type="text" @click="dialogVisible = true"> 提示 </el-button>
      </div>
      <div v-if="GET_INDEX_SCAN_TYPE != 13 && GET_INDEX_SCAN_TYPE != 11">
        {{ indexScan.scanTime }}
      </div>
      <div v-else>
        <span>注：点击图片查看原卷</span>
        <el-select v-model="indexArrange" class="img-nums" placeholder="请选择">
          <el-option
            v-for="item in arrangeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div
      v-if="GET_INDEX_SCAN_TYPE != 13 && GET_INDEX_SCAN_TYPE != 11"
      v-loading="loading"
      class="check-img-box"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!-- {{ GET_SCAN_ERR_LIST }} -->
      <div
        v-if="indexScanUrl"
        class="scan-img-box"
        @mousewheel="zoomRegion($event)"
      >
        <div ref="scanImgBox" class="room-img-box">
          <!-- {{ GET_INDEX_SHEET_MARK_LIST_SCAN[scanUrlCurrent].markLocation }} -->
          <template
            v-if="
              GET_INDEX_SCAN_TYPE == 3 &&
              GET_INDEX_SHEET_MARK_LIST_SCAN.length > 0 &&
              newLocation
            "
          >
            <div
              v-for="(v, i) in GET_INDEX_SHEET_MARK_LIST_SCAN[scanUrlCurrent]
                .markLocation"
              :key="i"
              class="move-box"
              :style="{
                left: getLocation(v, 'left'),
                top: getLocation(v, 'top'),
                width: getLocation(v, 'width'),
                height: getLocation(v, 'height'),
              }"
              @mousemove.prevent="mousemoveTest($event, i)"
              @mousedown.prevent="mousedownTest($event, v)"
              @mouseout.prevent="mouseout()"
            ></div>
            <!--  -->
          </template>
          <img ref="roomImg" :src="indexScanUrl" alt="" class="hidden-div" />
        </div>
      </div>
      <div v-else class="succ-box">
        <div>
          <img src="@/static/scanSheet/default-diagram.png" alt="" />
          <p>异常处理完成，老师您辛苦了!</p>
        </div>
      </div>
      <div class="check-box">
        <div
          v-for="(v, i) in indexScan.urlsList"
          :key="i"
          class="check-item-box"
          :class="{ on: i == scanUrlCurrent }"
          @click="checkImg(v, i)"
        >
          第{{ i + 1 }}面
        </div>
        <div class="btn-list">
          <template
            v-if="
              GET_INDEX_SCAN_TYPE == 3 ||
              GET_INDEX_SCAN_TYPE == 5 ||
              GET_INDEX_SCAN_TYPE == 15 ||
              GET_INDEX_SCAN_TYPE == 6
            "
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="交换试卷正反面"
              placement="left"
            >
              <el-button
                type="text"
                icon="el-icon-sort"
                :loading="rotateLoading"
                @click="exchange()"
              >
                交换
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="反向旋转90°"
              placement="left"
            >
              <el-button
                type="text"
                icon="el-icon-refresh-left"
                :loading="rotateLoading"
                @click="rotateImg(0)"
              >
                旋转
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="正向旋转90°"
              placement="left"
            >
              <el-button
                type="text"
                icon="el-icon-refresh-right"
                :loading="rotateLoading"
                @click="rotateImg(1)"
              >
                旋转
              </el-button>
            </el-tooltip>
          </template>

          <el-button type="text" icon="el-icon-zoom-in" @click="zoom(1)">
            放大
          </el-button>
          <el-button type="text" icon="el-icon-zoom-out" @click="zoom(0)">
            缩小
          </el-button>
        </div>
      </div>
    </div>
    <div v-else class="scan-img-item-box edit-scroll-style">
      <template v-if="GET_SCAN_ERR_LIST.length > 0">
        <div
          v-for="(v, i) in GET_SCAN_ERR_LIST"
          :key="i"
          class="scan-img-item"
          :class="{
            'one-img': indexArrange == 1,
            'two-img': indexArrange == 2,
            'three-img': indexArrange == 3,
          }"
        >
          <div>
            <div v-if="v.urls" class="img-box">
              <el-image
                :src="v.urls.split('，')[0]"
                :preview-src-list="v.urls.split('，')"
              ></el-image>
            </div>
            <div class="scan-img-msg">
              <div class="title">学校：{{ v.schoolName }}</div>
              <div>考号：{{ v.studentExamId }}</div>
              <div>姓名：{{ v.studentName }}</div>
              <template v-if="GET_INDEX_SCAN_TYPE == 13">
                <el-checkbox-group
                  v-if="userInfo.roleId != 5"
                  v-model="checkChangeList"
                  @change="checkChange"
                >
                  <el-checkbox :label="v.id" :disabled="v.absent == 0"
                    >设为非缺考</el-checkbox
                  >
                </el-checkbox-group>
              </template>
              <div v-else>
                <el-radio v-model="v.abType" :label="1" @change="checkAllList"
                  >A卷</el-radio
                >
                <el-radio v-model="v.abType" :label="2" @change="checkAllList"
                  >B卷</el-radio
                >
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="succ-box">
        <!-- 111111111111 -->
        <div>
          <img src="@/static/scanSheet/default-diagram.png" alt="" />
          <p>异常处理完成，老师您辛苦了!</p>
        </div>
      </div>
    </div>
    <div
      v-if="GET_INDEX_SCAN_TYPE == 11 && Math.ceil(GET_TOTAL / 6) > 0"
      class="page-box"
    >
      <i class="el-icon-arrow-left" @click="checkpage(0)"></i>
      <span>
        {{ GET_CURRENT }} /

        {{ Math.ceil(GET_TOTAL / 6) }}
      </span>

      <i class="el-icon-arrow-right" @click="checkpage(1)"></i>
    </div>
    <!-- 图像不完整提示 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <div v-if="GET_INDEX_SCAN_TYPE == 2">
        1、若答卷有效信息（定位区、考号区、作答区等）均完整，请点击“图像完整”，图像将自动进入下一个识别环节。
        <br />
        2、若答卷有效信息不完整，请参考批次信息找到纸质答卷，重新扫描后，针对该图像请点击“删除答卷”。
        <br />
        3、若误删除，可从回收站还原。<br />
        4、若大量异常均属同一类问题，请选择“批量操作”。
      </div>
      <div v-if="GET_INDEX_SCAN_TYPE == 3">
        <!-- 1、拖动定位点（或定位线及标题框）红框，使其与模板定位信息匹配后，请点击“重新识别”。<br /> -->
        1、若重新识别或者重扫均无法解决时，说明模板与答卷的定位信息、标题信息不匹配，请点击“标记异常卷”（模板为多张时不支持），确认考生信息后，考试管理员需在“异常卷”页面批改分数。<br />
        2、若答卷是扫错或多余的，请点击“删除答卷”。<br />
        3、若大量异常均属同一类问题，请选择“批量操作”。
      </div>
      <div v-if="GET_INDEX_SCAN_TYPE == 5 || GET_INDEX_SCAN_TYPE == 15">
        1、若答卷属于该学科考试，请点击“设为正常”。 <br />
        2、若答卷不属于该学科考试，请点击“删除答卷”。 <br />
        3、若大量异常均属同一类问题，请选择“批量操作”。
      </div>
      <div v-if="GET_INDEX_SCAN_TYPE == 6">
        1、若考号识别有误，请手动修改并搜索确认。 <br />
        2、若考号识别出来但不在计划考生列表内，请更新考生信息后再搜索确认。
        <br />
        3、若大量异常均需删除，请选择“批量操作”。
      </div>
      <div v-if="GET_INDEX_SCAN_TYPE == 7">
        1、若学生涂错考号，请先处理涂错学生的答卷；若另一个考号是正确的，直接搜索确认。
        <br />
        2、若重复扫描，请删除其中一个，另一个直接搜索确认。
      </div>
      <div v-if="GET_INDEX_SCAN_TYPE == 13">
        1、若答卷有作答痕迹，误涂缺考标记的，请点击“设为非缺考”；若答卷无作答痕迹，确实为缺考的，无需修改；每页修改完成后，请点击“提交本页”。
        <br />
        2、已处理列表中，确认非缺考的网阅卡，主观题需要老师阅卷。 <br />
        3、已处理页面不支持修改。
      </div>
      <div v-if="GET_INDEX_SCAN_TYPE == 11">
        1、若答卷AB卷填涂痕迹痕迹，误涂A
        B卷的，请点击“A卷或者B卷”；每页修改完成后，请点击“提交本页”。
        <br />
        2、已处理列表中，仍然可以修改AB卷，修改后请点击“提交本页”。
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
const arrangeList = [
  {
    value: 1,
    label: "一行1个",
  },
  {
    value: 2,
    label: "一行2个",
  },
  {
    value: 3,
    label: "一行3个",
  },
];
import { mapGetters } from "vuex";
import { updateurls } from "@/core/api/exam/examScanSheet";
import { getStore } from "@/core/util/store";
export default {
  name: "ScanCheckBox",
  data() {
    return {
      arrangeList: arrangeList,
      newLocation: true,
      indexArrange: 2,
      scanUrlCurrent: 3,
      indexScan: {},
      indexScanUrl: "",
      loading: false,
      dialogVisible: false,
      rotateLoading: false,
      checkChangeList: [],
      indexQuestion: {},
      userInfo: {},
    };
  },
  computed: {
    ...mapGetters([
      //  GET_TOTAL GET_CURRENT   // SET_CHECK_ALL SET_ISBATCH
      "GET_INDEX_SCAN_ERR",
      "GET_TOTAL",
      "GET_CURRENT",
      "GET_INDEX_SCAN_TYPE",
      "GET_SCAN_ERR_LIST",
      "GET_CHECK_ALL",
      "GET_SHEET_MARK_LIST",
      "GET_ISBATCH",
      "GET_INDEX_SHEET_MARK_LIST_SCAN",
      "GET_SHEET_MARK_LIST_SCAN",
    ]),
  },
  watch: {
    GET_INDEX_SCAN_ERR: {
      deep: true,
      handler() {
        this.setIndexMsg();
      },
    },
    GET_SIGN_ERR_LIST: {
      deep: true,
      handler() {
        if (this.GET_SIGN_ERR_LIST.length == 0) {
          this.checkChangeList = [];
        }
      },
    },
    GET_SCAN_ERR_LIST: {
      immediate: true,
      handler() {
        this.checkChangeList = [];
        this.GET_SCAN_ERR_LIST.map((item) => {
          if (item.absent == 2) {
            this.checkChangeList.push(item.id);
          }
        });
        this.$store.commit("SET_SIGN_ERR_LIST", this.checkChangeList);
      },
    },
  },

  created() {
    this.userInfo = getStore({ name: "userInfo" });
    if (this.GET_INDEX_SCAN_ERR.urls) {
      this.setIndexMsg();
    }
  },
  mounted() {
    // document.body.addeventlistener
    document.body.addEventListener("mouseup", this.mouseupAll);
  },
  destroyed() {
    // 移除鼠标移动事件
    // document.removeEventListener("mousemove", this.mousemoveAll);
    // 移除鼠标放开事件
    document.body.removeEventListener("mouseup", this.mouseupAll);
    // window.removeEventListener("mousedown", this.handleonmousedown);
  },
  methods: {
    // 鼠标抬起
    mouseupAll() {
      // 如果是非抬起状态  不管
      let practice_item = document.getElementsByClassName("hidden-div");
      let practice_item_length = practice_item.length;
      for (let i = 0; i < practice_item_length; i++) {
        practice_item[i].style.pointerEvents = "";
        practice_item[i].style.userSelect = "";
      }
      this.mouseupTest();
    },
    //鼠标长按移动盒子 start
    mouseout() {
      // this.dragType = false;
      // this.indexQuestion = {};
    },
    // 当鼠标指针在指定的元素中移动时
    mousemoveTest($event, i) {
      if (!this.dragType) {
        return;
      }
      this.indexBox = i;
      let { clientWidth, clientHeight } = this.$refs.scanImgBox;
      this.clientWidth = clientWidth;
      this.clientHeight = clientHeight;
      const proportion = this.oldWidth / this.clientWidth;
      const { endMouseTop, endMouseLeft } = this.mouseDragState;

      // let x = $event.x - this.mouseDragState.endMouseLeft;
      // let y = $event.y - this.mouseDragState.endMouseTop;

      // 计算鼠标移动的距离
      const distance = Math.abs(
        parseInt(((endMouseTop - $event.clientY) * 100).toString(), 10) / 100
      );
      const distanceLeft = Math.abs(
        parseInt(((endMouseLeft - $event.clientX) * 100).toString(), 10) / 100
      );
      // let ques;
      if (endMouseLeft <= $event.clientX) {
        this.indexQuestion.source.left =
          this.indexQuestion.source.left + distanceLeft * proportion;
      } else {
        this.indexQuestion.source.left =
          this.indexQuestion.source.left - distanceLeft * proportion;
      }
      //  最小值限制 start
      if (endMouseTop <= $event.clientY) {
        this.indexQuestion.source.top =
          this.indexQuestion.source.top + distance * proportion;
      } else {
        this.indexQuestion.source.top =
          this.indexQuestion.source.top - distance * proportion;
      }
      if (this.indexQuestion.source.top <= 1) {
        this.indexQuestion.source.top = 1;
      }
      if (this.indexQuestion.source.left <= 1) {
        this.indexQuestion.source.left = 1;
      }
      // 最小值限制 end
      // 最大值限制 start
      // 到右侧最大距离
      if (
        this.indexQuestion.source.left + this.indexQuestion.source.width >=
        this.clientWidth * proportion
      ) {
        this.indexQuestion.source.left =
          this.clientWidth * proportion - this.indexQuestion.source.width - 1;
      }
      // 到下方最大距离
      if (
        this.indexQuestion.source.top + this.indexQuestion.source.height >=
        this.clientHeight * proportion
      ) {
        this.indexQuestion.source.top =
          this.clientHeight * proportion - this.indexQuestion.source.height - 1;
      }
      let el = $event.target;
      el.left = this.indexQuestion.source.left + "px";
      el.top = this.indexQuestion.source.top + "px";
      el.style.zIndex = 10;
      // this.GET_INDEX_SHEET_MARK_LIST_SCAN[this.scanUrlCurrent].markLocation[i] =
      //   Object.assign({}, this.indexQuestion);

      // console.log(this.indexQuestion);
      // 更新鼠标最后移动的位置（Y轴）
      this.mouseDragState.endMouseTop = $event.clientY;
      this.mouseDragState.endMouseLeft = $event.clientX;
    },
    // 按下触发 event 鼠标事件v 当前点击的盒子  type 判断是那种拖动 indexF追加区域情况，当前父元素
    mousedownTest($event, v) {
      let str = "";
      const urlPath =
        $event.path || ($event.composedPath && $event.composedPath());
      urlPath.map((index) => {
        str = str + index.className;
      });
      let practice_item = document.getElementsByClassName("hidden-div");
      let practice_item_length = practice_item.length;
      for (let i = 0; i < practice_item_length; i++) {
        practice_item[i].style.pointerEvents = "none";
        practice_item[i].style.userSelect = "none";
      }

      // console.log(this.sheetLineList);
      this.mouseDragState = {
        // 鼠标开始移动的位置（Y轴）
        startMouseTop: $event.clientY,
        // 鼠标最后到的位置（Y轴）
        endMouseTop: $event.clientY,
        // 鼠标开始移动的位置(x轴)
        startMouseLeft: $event.clientX,
        // 鼠标最后到的位置（X轴）
        endMouseLeft: $event.clientX,
      };
      this.dragType = true;
      this.indexQuestion = Object.assign({}, v);
    },
    // 抬起触发
    mouseupTest() {
      // console.log(this.indexQuestion);
      if (!this.indexQuestion.type) {
        this.indexQuestion = {};
        return;
      }
      // console.log("222222222");
      let move_box = document.getElementsByClassName("move-box");
      let move_box_length = move_box.length;
      for (let i = 0; i < move_box_length; i++) {
        move_box[i].style.zIndex = 0;
      }
      let list = JSON.parse(
        JSON.stringify(this.GET_INDEX_SHEET_MARK_LIST_SCAN)
      );
      list[this.scanUrlCurrent].markLocation[this.indexBox] = JSON.parse(
        JSON.stringify(this.indexQuestion)
      );
      this.dragType = false;
      this.indexQuestion = {};

      this.$store.commit("SET_INDEX_SHEET_MARK_LIST", list);
      // console.log(this.GET_INDEX_SHEET_MARK_LIST_SCAN);
    },
    getLocation(v, type) {
      // console.log(v);
      let proportion = this.oldWidth / this.clientWidth;
      let val = (v.source[type] / proportion).toFixed(2) + "px";
      return val;
    },
    callFunction(img) {
      return new Promise((resolve) => {
        img.onload = () => {
          this.oldWidth = img.width;
          this.oldHeight = img.height;
          resolve(true);
        };
      });
    },
    async setImg() {
      // 创建img对象
      let img = document.createElement("img");
      // console.log(this.$refs.scanImgBox);
      // 设置src值
      img.src = encodeURI(this.indexScanUrl);
      const _this = this;
      //设置canvas属性 oldWidth oldHeight clientWidth
      await this.callFunction(img);
      _this.oldWidth = img.width;
      _this.oldHeight = img.height;
    },
    rotateImg(type) {
      let array = [];
      if (this.GET_ISBATCH) {
        array = this.GET_SCAN_ERR_LIST.filter(
          (item) => this.GET_CHECK_ALL.indexOf(item.id) != -1
        );
      } else {
        array = [this.indexScan];
      }
      array = JSON.parse(JSON.stringify(array));
      array.map((index, i) => {
        array[i] = this.rotate(type, index);
      });
      this.updateurls(array);
    },
    // 旋转图片
    rotate(type, listData) {
      // 页面显示的markurl
      // 需要将显示的和不显示的都修改
      let url = listData.urls.split("，")[this.scanUrlCurrent];

      this.indexScan.urlsList = listData.urls.split("，");

      let rotateDeg = 0;
      let sharpenStr = "";
      let rotateList = url.split("image/rotate,");
      let sharpen = url.split("image/sharpen,");

      if (rotateList[1]) {
        rotateDeg = Number(rotateList[1].split(",")[0]);
      }
      if (sharpen[1]) {
        sharpenStr = sharpen[1].split(",")[0];
      }
      let urls = listData.urls.split("，");

      if (type) {
        rotateDeg = Number(rotateDeg) + 90;
      } else {
        rotateDeg = Number(rotateDeg) - 90;
      }
      // console.log(rotateDeg);
      if (rotateDeg < 0) {
        rotateDeg = 360 + rotateDeg;
      }
      rotateDeg = rotateDeg % 360;
      if (rotateDeg == 0 || rotateDeg == 360) {
        url = "";
      } else {
        url = "?x-oss-process=image/rotate," + rotateDeg;
      }
      // console.log(url);
      // 做了次修改 旋转之后  图片改为普通的urls
      if (this.scanUrlCurrent != 0) {
        urls[this.scanUrlCurrent] =
          urls[this.scanUrlCurrent].split("，")[0].split("?")[0] + url;
        if (sharpenStr) {
          urls[this.scanUrlCurrent] =
            urls[this.scanUrlCurrent] + ",image/sharpen," + sharpenStr;
        }
      } else {
        urls.map((item, i) => {
          urls[i] = item.split("，")[0].split("?")[0] + url;
          if (sharpenStr) {
            urls[i] = urls[i] + ",image/sharpen," + sharpenStr;
          }
        });
      }
      if (this.indexScan.id == listData.id) {
        this.indexScanUrl = urls[this.scanUrlCurrent];
      }
      let data = {
        id: listData.id,
        markUrls: urls.join("，"),
        urls: urls.join("，"),
      };
      return data;
      //
    },
    // 交换图片
    exchange() {
      let array = [];
      if (this.GET_ISBATCH) {
        array = this.GET_SCAN_ERR_LIST.filter(
          (item) => this.GET_CHECK_ALL.indexOf(item.id) != -1
        );
      } else {
        array = [this.indexScan];
      }
      array = JSON.parse(JSON.stringify(array));

      array.map((index) => {
        index.markUrls = index.markUrls.split("，").reverse();
        index.urls = index.urls.split("，").reverse();

        index.markUrls = index.markUrls.join("，");
        index.urls = index.urls.join("，");
      });

      // let data = {
      //   id: this.GET_INDEX_SCAN_ERR.id,
      //   markUrls: markUrls.join("，"),
      //   urls: urls.join("，"),
      // };
      this.updateurls(array);
    },
    updateurls(data) {
      this.rotateLoading = true;
      updateurls(data)
        .then(() => {
          this.rotateLoading = false;
          data.map((index) => {
            if (index.id == this.GET_INDEX_SCAN_ERR.id) {
              this.$store.commit(
                "SET_INDEX_SCAN_ERR",
                Object.assign(this.indexScan, index)
              );
              this.setIndexMsg();
            }
          });
          this.$store.commit("SET_INDEX_URL", data);
        })
        .catch(() => {
          this.rotateLoading = false;
        });
    },
    zoom(val) {
      // let height = this.$refs.scanImgBox.height;
      let { clientWidth, clientHeight } = this.$refs.scanImgBox;
      if (val) {
        if (clientHeight < 3000) {
          clientHeight = clientHeight / 0.9;
          clientWidth = clientWidth / 0.9;
        }
      } else {
        if (clientHeight > 200) {
          clientHeight = clientHeight * 0.9;
          clientWidth = clientWidth * 0.9;
        }
      }
      this.clientWidth = clientWidth;
      this.clientHeight = clientHeight;
      this.newLocation = false;
      this.$nextTick(() => {
        this.$refs.scanImgBox.style.height = clientHeight + "px";
        this.$refs.scanImgBox.style.width = clientWidth + "px";
        this.newLocation = true;
      });
    },
    checkpage(type) {
      let page = this.GET_CURRENT;
      if (!type) {
        if (this.GET_CURRENT > 1) {
          page--;
        }
      } else {
        if (this.GET_CURRENT < Math.ceil(this.GET_TOTAL / 6)) {
          page++;
        }
      }
      this.$store.commit("SET_CURRENT", page);
      this.$emit("getErrorList");
      this.$store.commit("SET_SIGN_ERR_LIST", []);
    },
    // 缩放区域
    zoomRegion($event) {
      if ($event.ctrlKey) {
        $event.preventDefault();
        let { clientWidth, clientHeight } = this.$refs.scanImgBox;
        if ($event.wheelDelta > 0) {
          if (clientHeight < 3000) {
            clientHeight = clientHeight / 0.9;
            clientWidth = clientWidth / 0.9;
          }
        } else {
          if (clientHeight > 200) {
            clientHeight = clientHeight * 0.9;
            clientWidth = clientWidth * 0.9;
          }
        }
        this.clientWidth = clientWidth;
        this.clientHeight = clientHeight;
        this.newLocation = false;
        this.$nextTick(() => {
          this.$refs.scanImgBox.style.height = clientHeight + "px";
          this.$refs.scanImgBox.style.width = clientWidth + "px";
          this.newLocation = true;
        });
      }
    },
    checkChange() {
      this.$store.commit("SET_SIGN_ERR_LIST", this.checkChangeList);
    },
    checkAllList() {
      // console.log(this.GET_SCAN_ERR_LIST);
      // this.$store.commit("SET_SIGN_ERR_LIST", this.checkAllList);
    },
    checkImg(v, i) {
      this.indexScanUrl = v;
      this.scanUrlCurrent = i;
    },
    setIndexMsg() {
      this.indexScan = JSON.parse(JSON.stringify(this.GET_INDEX_SCAN_ERR));
      if (this.indexScan.urls) {
        this.indexScan.urlsList = this.indexScan.markUrls.split("，");
        if (this.GET_INDEX_SCAN_TYPE == 2 || this.GET_INDEX_SCAN_TYPE == 3) {
          this.indexScan.urlsList = this.indexScan.urls.split("，");
        }
        if (!this.indexScan.markUrls) {
          this.indexScan.urlsList = this.indexScan.urls.split("，");
        }
        // 判断下 如果图片前缀相同  就不做替换
        let type = true;
        if (this.indexScanUrl) {
          this.indexScan.urlsList.forEach((item, i) => {
            let urls = item.split("?");
            if (this.indexScanUrl.split("?")[0] == urls[0]) {
              type = false;
              this.scanUrlCurrent = i;
              this.$nextTick(() => {
                this.indexScanUrl = item;
              });
            }
          });
        }
        if (type) {
          this.$nextTick(() => {
            this.indexScanUrl = this.indexScan.urlsList[0];
            this.scanUrlCurrent = 0;
          });
        }
      } else {
        this.indexScan.markUrls = [];
        this.indexScanUrl = "";
      }
      this.clientWidth = 0;
      this.clientHeight = 0;
      this.loading = true;
      if (!this.indexScanUrl) {
        this.loading = false;
      }
      setTimeout(() => {
        this.initView();
      }, 200);
    },
    async initView() {
      if (this.$refs.scanImgBox) {
        let { clientWidth, clientHeight } = this.$refs.scanImgBox;
        this.clientWidth = clientWidth;
        this.clientHeight = clientHeight;

        await this.setImg();
        this.loading = false;
        let array = [];
        this.GET_SHEET_MARK_LIST_SCAN.map((item) => {
          let { id, markLocation } = item;
          markLocation = JSON.parse(markLocation);

          array.push({
            id: id,
            markLocation: markLocation,
          });
        });
        this.$store.commit("SET_INDEX_SHEET_MARK_LIST", array);
        // console.log(this.GET_INDEX_SHEET_MARK_LIST_SCAN);
      } else {
        if (this.GET_INDEX_SCAN_TYPE != 3) {
          return;
        }
        setTimeout(() => {
          this.initView();
        }, 200);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.scanCheckBox {
  // width: calc(100vw - 430px);
  width: calc(100% - 440px);
  position: relative;
  .page-box {
    position: absolute;
    left: 50%;
    bottom: 0;
    background: #f3f3f3;
    color: #b9b9b9;
    padding: 8px 12px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
    transform: translateX(-50%);
    > span {
      margin: 0 50px;
    }
    i {
      cursor: pointer;
    }
  }
  .scan-img-item-box {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 24px 12px;
    background-color: #f4f4f4;
    height: calc(100vh - 150px);
    // margin-left: -12px;
    // margin-right: -12px;
    .scan-img-item {
      > div {
        background-color: #ffffff;
      }
      margin-bottom: 24px;
      padding-left: 12px;
      padding-right: 12px;
      // margin-left: 12px;
      // margin-right: 12px;
      .scan-img-msg {
        border-top: 1px solid #d8d8d8;
        padding: 24px;
        display: flex;
        justify-content: space-between;
        color: #aaaaaa;
        flex-wrap: wrap;
      }
      .img-box {
        padding: 24px 0;
        text-align: center;

        .el-image {
          height: 100%;
          //
          ::v-deep img {
            width: auto;
          }
        }
      }
    }
    .one-img {
      width: 100%;

      .img-box {
        height: 420px;
      }
    }
    .two-img {
      width: 50%;
      .img-box {
        height: 384px;
      }
    }
    .three-img {
      width: 33%;
      .title {
        width: 100%;
        margin-bottom: 12px;
      }
      .img-box {
        height: 249px;
      }
    }
  }
  .check-img-box {
    display: flex;
    justify-content: space-between;
  }
  .check-box {
    height: calc(100vh - 141px);
    width: 84px;
    padding: 24px 8px;
    background: #ffffff;
    // display: flex;
    .btn-list {
      position: absolute;
      bottom: 12px;
      background: #ffffff;
      .el-button {
        margin-left: 0;
        margin-top: 26px;
      }
    }
    .check-item-box {
      // margin: 0 auto;
      width: 64px;
      text-align: center;
      line-height: 28px;
      border-radius: 2px;
      margin-bottom: 10px;
      color: #aaaaaa;
      cursor: pointer;
      border: 1px solid #aaaaaa;
    }
    .on {
      color: #ffffff;
      background-color: $primary-color;
      border: 1px solid $primary-color;
    }
    // align-items: ;
  }
  .scan-check-header {
    padding: 16px 24px;
    background-color: #f4f4f4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #525252;
    border-bottom: 1px solid #e7e7e7;
    .img-nums {
      width: 100px;
      height: 28px;
      line-height: 28px;
      margin-left: 24px;
      ::v-deep .el-input__inner {
        height: 28px;
        line-height: 28px;
      }
    }
  }
  .succ-box {
    height: calc(100vh - 141px);
    width: calc(100% - 94px);
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-wrap: wrap;
    p {
      width: 100%;
      color: #525252;
      margin-bottom: 0;
      margin-top: 12px;
    }
  }
  .scan-img-box {
    overflow: scroll;
    padding: 30px 20px;
    background-color: #f3f3f3;
    height: calc(100vh - 141px);
    width: calc(100% - 94px);
    // text-align: center;
    .room-img-box {
      position: relative;
      > div {
        position: absolute;
        padding: 2px;
        border: 2px solid red;
        cursor: move;
        &:hover {
          background-color: rgba(255, 0, 0, 0.4);
        }
      }
      img {
        width: 100%;
      }
    }
    // img {
    //   height: 100%;
    //   min-height: 500px;
    // }
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #dee1e7;
    }
  }
}
</style>
