<template>
  <div class="scanErrTable hidden-div">
    <div v-if="$route.query.isView != 0" class="type-list-box">
      <div
        v-for="(v, i) in abnormalList"
        :key="i"
        class="type-list"
        :class="{ 'index-succ': indexType == v.value }"
        @click="checkType(v, i)"
      >
        <i v-if="v.value != 2" class="el-icon-more"></i>
        {{ v.label }}
        <span
          v-if="v.number"
          class="tips"
          :style="{ right: setValue(v.number) }"
        >
          {{ v.number }}
        </span>
      </div>
    </div>
    <div v-else class="type-list-box">
      <template v-for="(v, i) in abnormalList">
        <div
          v-if="v.value == 14"
          :key="i"
          class="type-list"
          :class="{ 'index-succ': indexType == v.value }"
          @click="checkType(v, i)"
        >
          {{ v.label }}
          <span
            v-if="v.number"
            class="tips"
            :style="{ right: setValue(v.number) }"
          >
            {{ v.number }}
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const abnormalList = [
  {
    label: "图像不完整",
    value: 2,
    number: 0,
    handnumber: 0,
  },
  {
    label: "定位异常",
    value: 3,
    number: 0,
    handnumber: 0,
  },
  {
    label: "标题异常",
    value: 15,
    number: 0,
    handnumber: 0,
  },
  {
    label: "学科异常",
    value: 5,
    number: 0,
    handnumber: 0,
  },
  {
    label: "考号填涂异常",
    value: 6,
    number: 0,
    handnumber: 0,
  },
  {
    label: "考号重复",
    value: 7,
    number: 0,
    handnumber: 0,
  },
  {
    label: "缺考异常",
    value: 13,
    number: 0,
    handnumber: 0,
  },
  {
    label: "客观题异常",
    value: 14,
    number: 0,
    handnumber: 0,
  },
];

import {
  scanrecordunionErrortypeNums,
  basesubquestionexceptions,
  questionCount,
} from "@/core/api/exam/examScanSheet";
import { mapGetters } from "vuex";
export default {
  name: "ScanErrTable",
  props: {
    sheetInfomation: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      abnormalList: abnormalList,
      indexType: 2,
    };
  },
  ...mapGetters(["GET_INDEX_SCAN_TYPE", "GET_SHEET_INFOMATION"]),
  watch: {
    GET_INDEX_SCAN_TYPE: {
      deep: true,
      handler() {
        // console.log("111111");
        this.indexType = this.GET_INDEX_SCAN_TYPE;
      },
    },
    sheetInfomation: {
      deep: true,
      handler() {
        // console.log(this.sheetInfomation);
        if (!this.sheetInfomation) {
          return;
        }
        this.getshowVal();
      },
    },
  },
  created() {
    this.init();
    // console.log(this.GET_INDEX_SCAN_TYPE);
    if (this.GET_INDEX_SCAN_TYPE) {
      this.indexType = this.GET_INDEX_SCAN_TYPE;
    }
    if (this.sheetInfomation) {
      this.getshowVal();
    }
    // this.scanrecordunionErrortypeNums();
  },
  methods: {
    init() {
      if (this.$route.query.isView == 0) {
        this.$store.commit("SET_IS_HANDLE", 0);
        this.indexType = 14;
        this.$store.commit("SET_INDEX_SCAN_TYPE", 14);
        this.$store.commit("SET_SCAN_ERR_LIST", []);
        this.$store.commit("SET_INDEX_SCAN_ERR", {});

        // console.log(this.GET_INDEX_SCAN_TYPE);
      }
    },
    basesubquestionexceptions() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      basesubquestionexceptions(data).then((res) => {
        // console.log(res);
        this.abnormalList.map((item) => {
          if (item.value == 16) {
            item.number = 0;
          }
          res.data.data.map((index) => {
            if (item.value == 16 && index.questionExceptionStatus != 1) {
              item.number = item.number + Number(index.count);
            }
          });
        });
        // console.log(this.abnormalList);
        this.$store.commit("SET_ABNORMAL_LIST", this.abnormalList);
      });
    },
    setErrValue(number) {
      this.abnormalList.map((item) => {
        if (item.value == 18) {
          item.number = number;
        }
      });
    },
    getshowVal() {
      let data = {
        label: "主观题得分矫正",
        value: 16,
        number: 0,
        handnumber: 0,
      };
      let data2 = {
        label: "A-B卷异常",
        value: 11,
        number: 0,
        handnumber: 0,
      };
      // console.log(this.GET_SHEET_INFOMATION);
      let arr = this.abnormalList.map((item) => item.value);
      if (this.sheetInfomation.markType) {
        if (arr.indexOf(16) == -1) {
          this.abnormalList.push(data);
        }
        if (!this.errType) {
          this.basesubquestionexceptions();
        } else {
          this.errType = false;
        }
      }
      if (this.sheetInfomation.abType) {
        if (arr.indexOf(11) == -1) {
          this.abnormalList.push(data2);
        }
      }
      if (!this.errorList) {
        return;
      }
      this.errorList.forEach((item) => {
        this.abnormalList.map((index) => {
          if (item.errorTypeId == index.value && !item.isHandle) {
            index.number = item.number;
          }
          if (item.errorTypeId == index.value && item.isHandle) {
            index.handnumber = item.number;
          }
          if (item.errorTypeId == 100) {
            index.allNumber = item.number;
          }
        });
        this.$store.commit("SET_ABNORMAL_LIST", this.abnormalList);
      });
    },
    setValue(number) {
      let str = "";
      if (number < 10) {
        str = "-16px";
      }
      if (number >= 10 && number < 100) {
        str = "-24px";
      }
      if (number > 99) {
        str = "-32px";
      }
      return str;
    },
    // 获取异常数量
    getQuestionCount() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      questionCount(data).then((res) => {
        let { untreated } = res.data.data;

        this.setErrValue(untreated);
      });
    },
    errortypeNums(type) {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      if (type) data.type = type;
      scanrecordunionErrortypeNums(data).then((res) => {
        this.abnormalList.map((item) => {
          item.number = 0;
          item.handnumber = 0;
        });
        if (this.sheetInfomation.markType) {
          this.errType = true;
          this.basesubquestionexceptions();
        } else {
          if (this.$route.query.password) {
            let data = {
              label: "选做题异常",
              value: 18,
              number: 0,
              handnumber: 0,
            };
            let arr = this.abnormalList.filter(
              (item) => item.value == data.value
            );
            if (arr.length == 0) {
              this.abnormalList.push(data);
            }
            this.getQuestionCount();
          }
        }
        this.errorList = res.data.data;
        if (res.data.data.length > 0) {
          res.data.data.forEach((item) => {
            this.abnormalList.map((index) => {
              if (item.errorTypeId == index.value && !item.isHandle) {
                index.number = item.number;
              }
              if (item.errorTypeId == index.value && item.isHandle) {
                index.handnumber = item.number;
              }
              if (item.errorTypeId == 100) {
                index.allNumber = item.number;
              }
            });
            this.$store.commit("SET_ABNORMAL_LIST", this.abnormalList);
          });
        }
      });
    },
    checkType(v) {
      if (v.value == this.indexType) {
        return;
      }
      this.$store.commit("SET_IS_HANDLE", 0);
      this.indexType = v.value;
      this.$store.commit("SET_INDEX_SCAN_TYPE", v.value);
      this.$store.commit("SET_SCAN_ERR_LIST", []);
      this.$store.commit("SET_INDEX_SCAN_ERR", {});
    },
  },
};
</script>
<style lang="scss" scoped>
.scanErrTable {
  padding: 12px 24px;
  border-bottom: 1px solid #e7e7e7;
  .type-list-box {
    display: flex;
    color: #525252;
    // position: absolute;
    .type-list {
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      .tips {
        letter-spacing: 0;
        position: absolute;
        padding: 0px 4px;
        border-radius: 10px;
        background-color: #f56c6b;
        font-size: 12px;
        color: #ffffff;
        right: 0;
        top: -10px;
      }
    }
    .el-icon-more {
      margin: 0 24px;
    }
    .index-number {
      font-size: 12px;
      margin-right: 4px;
      width: 15px;
      height: 15px;
      text-align: center;
      border: 1px solid #525252;
      border-radius: 50%;
      line-height: 13px;
      letter-spacing: 0;
      display: inline-block;
    }
    .index-succ {
      color: $primary-color;
      .index-number {
        border-color: $primary-color;
        color: $primary-color;
      }
    }
  }
}
</style>
