<template>
  <div class="optionalTest">
    <div class="optional-box">
      <div class="scan-check-header">异常原因：选做题未涂或多涂</div>
      <div ref="objective" class="optional-list-box edit-scroll-style">
        <template v-if="questionList.length > 0">
          <div
            v-for="(v, i) in questionList"
            :key="i"
            v-loading="listLoading"
            class="optional-item"
          >
            <div class="img-box">
              <img :src="v.answerUrl" alt="" />
            </div>
            <div class="check-question-box">
              <div
                v-for="(item, key) in indexQuestion.questionNumGroup.split(',')"
                :key="key"
                :class="{ on: v.questionNum == item }"
                @click="checkQuestion(v, item)"
              >
                {{ item }}
              </div>
              <div :class="{ on: v.isBlank == 1 }" @click="checkWihte(v)">
                空白题
              </div>
              <el-button type="text" @click="dblclick(v)">查看原卷</el-button>
            </div>
          </div>
          <el-button
            plain
            class="sub-btn"
            icon="el-icon-circle-check"
            :loading="btnLoading"
            type="primary"
            @click="submit"
          >
            提交本页
          </el-button>
        </template>
        <div v-if="!listLoading && questionList.length == 0" class="succ-box">
          <div>
            <img src="@/static/scanSheet/default-diagram.png" alt="" />
            <p>异常处理完成，老师您辛苦了!</p>
          </div>
        </div>
      </div>
      <div class="page-box">
        <el-pagination
          :current-page="pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 右侧组 -->
    <div class="group-box">
      <div class="group-box-header">
        <div class="sacn-err-header-title">
          {{ $parent.sheetInfomation.schoolName }}
        </div>
        <div class="check-tab">
          <div
            v-for="(v, i) in typeList"
            :key="i"
            :class="{ 'check-tab-on': indexType == v.value }"
            @click="checkTab(v)"
          >
            {{ v.name }}：（{{ v.number }}）
          </div>
        </div>
      </div>
      <!-- 题组列表 -->
      <div class="question-list-box edit-scroll-style">
        <div
          v-for="(v, i) in groupList"
          :key="i"
          class="paper-item absent-list"
          :class="{
            on: indexQuestion.questionGroupId == v.questionGroupId,
          }"
          @click="checkIndexPaper(v)"
        >
          {{ v.questionNumGroup }} <span>{{ v.number }}</span>
          <i class="el-icon-caret-left"></i>
        </div>
      </div>
    </div>
    <el-image
      v-show="false"
      ref="imga"
      src=""
      fit="cover"
      style="width: 100%; height: 100%"
      :preview-src-list="imgSrc"
    >
    </el-image>
  </div>
</template>

<script>
import {
  chooseBase,
  questionCount,
  chooseQuestionList,
  blankList,
} from "@/core/api/exam/examScanSheet";
export default {
  name: "OptionalTest",
  components: {},
  data() {
    return {
      imgSrc: [],
      groupList: [],
      questionList: [],
      indexQuestion: {},
      pageSize: 1,
      size: 20,
      pageTotal: 0,
      btnLoading: false,
      listLoading: false,
      indexType: 2,
      typeList: [
        {
          name: "全部",
          value: 1,
          number: 0,
        },
        {
          name: "待处理",
          value: 2,
          number: 0,
        },
        {
          name: "已处理",
          value: 3,
          number: 0,
        },
      ],
    };
  },
  created() {
    this.getChooseBase();
    this.getQuestionCount();
  },
  methods: {
    dblclick(row) {
      // this.imgSrc = [];
      this.imgSrc = row.urls.split("，");
      //这里要用$nextTick() 解决第一次不生效的问题
      this.$nextTick(() => {
        this.$refs.imga.clickHandler();
      });
    },

    getFunImg(item) {
      let answerArea = "";
      if (!item.answerArea) {
        return;
      }
      //   if (item.answerArea) {
      answerArea = JSON.parse(item.answerArea);
      //   }
      // 获取大框的位置
      let { source } = answerArea;
      // 设置比例  如果图片大于700  处理下
      let allWidth = source.width;
      let proportion = 1;
      if (allWidth > 700) {
        proportion = allWidth / 700;
      }
      let width = `width:${source.width / proportion}px;`;
      let height = `height:${source.height / proportion}px;`;

      return width + height;
    },
    // 切换size
    handleSizeChange(val) {
      this.size = val;
      this.$refs.objective.scrollTo(0, 0);
      this.questionList = [];
      this.getChooseQuestionList();
    },
    handleCurrentChange(val) {
      this.pageSize = val;
      this.$refs.objective.scrollTo(0, 0);
      this.questionList = [];
      this.getChooseQuestionList();
    },
    checkTab(v) {
      this.indexType = v.value;
      this.pageSize = 1;
      this.getChooseBase();
    },
    //
    submit() {
      let arr = this.questionList.map((item) => {
        return {
          examPaperId: item.examPaperId,
          isBlank: item.isBlank,
          examQuestionGroup: item.examQuestionGroup,
          studentId: item.studentId,
          questionNum: item.questionNum,
        };
      });
      arr = arr.filter((item) => item.isBlank || item.questionNum);
      this.setblankList(arr);
    },
    setblankList(data) {
      this.btnLoading = true;
      this.listLoading = true;
      blankList(data)
        .then(() => {
          this.btnLoading = false;
          this.listLoading = false;
          this.getChooseBase();
          this.getQuestionCount();
          this.$parent.$refs.scanErrTable.errortypeNums(this.objectiveType);
          this.$refs.objective.scrollTo(0, 0);
          this.$message({
            message: "设置成功",
            type: "success",
            showClose: true,
          });
        })
        .catch(() => {
          this.listLoading = false;
          this.btnLoading = false;
        });
    },
    // 切换空白题
    checkWihte(v) {
      this.questionList = this.questionList.map((item) => {
        if (item.studentId == v.studentId) {
          item.isBlank = 1;
          item.questionNum = null;
        }
        return item;
      });
    },
    // 切换试题
    checkQuestion(v, question) {
      this.questionList = this.questionList.map((item) => {
        if (item.studentId == v.studentId) {
          item.isBlank = null;
          item.questionNum = question;
        }
        return item;
      });
    },
    checkIndexPaper(item) {
      this.indexQuestion = item;
      this.pageSize = 1;
      this.getChooseQuestionList();
    },
    // 获取选做题
    getChooseQuestionList() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        type: this.indexType,
        examQuestionGroup: this.indexQuestion.questionGroupId,
        current: this.pageSize,
        size: this.size,
      };
      this.listLoading = true;
      chooseQuestionList(data)
        .then((res) => {
          res.data.data.records.map((item) => {
            //   item.isBlank = null;
            item.questionNum = "";
            if (item.questionExceptionStatus <= 1) {
              item.questionNum = item.questionNum2;
            }
          });
          this.questionList = res.data.data.records;
          this.pageTotal = res.data.data.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    // 获取选做题题组
    getChooseBase() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        type: this.indexType,
      };
      chooseBase(data).then((res) => {
        this.groupList = res.data.data;
        if (this.groupList.length == 0) {
          this.questionList = [];
          return;
        }
        if (this.indexQuestion) {
          let arr = this.groupList.filter(
            (item) => item.questionGroupId == this.indexQuestion.questionGroupId
          );
          if (arr.length == 0) {
            this.indexQuestion = res.data.data[0];
          }
        } else {
          this.indexQuestion = res.data.data[0];
        }

        this.getChooseQuestionList();
      });
    },
    // 获取异常数量
    getQuestionCount() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      questionCount(data).then((res) => {
        let { all, processed, untreated } = res.data.data;
        this.typeList[0].number = all;
        this.typeList[1].number = untreated;
        this.typeList[2].number = processed;
        // this.$parent.$refs.scanErrTable.setErrValue(untreated);
        //
      });
    },
  },
};
</script>
<style scoped lang="scss">
.optionalTest {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .sub-btn {
    display: block;
    margin: 24px auto 60px;
  }
  .succ-box {
    height: calc(100vh - 166px);
    width: calc(100% - 94px);
    background: #f3f3f3;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-wrap: wrap;
    p {
      width: 100%;
      color: #525252;
      margin-bottom: 0;
      margin-top: 12px;
    }
  }
  .question-list-box {
    height: calc(100vh - 210px);
    &::-webkit-scrollbar-thumb {
      background-color: $primary-color;
    }
    .absent-list {
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      > span {
        position: absolute;
        right: 24px;
        top: 15px;
      }
    }
    .paper-item {
      padding: 15px 0 15px 50px;
      position: relative;
      cursor: pointer;
      ::v-deep .el-checkbox-group {
        position: absolute;
        right: 24px;
        top: 15px;
        .el-checkbox__label {
          display: none;
        }
      }
      .el-icon-caret-left {
        position: absolute;
        opacity: 0;
        font-size: 18px;
      }
    }
    .on {
      background: #f2f5f8;

      .el-icon-caret-left {
        // position: absolute;
        opacity: 1;
        left: 29px;
        top: 16px;
      }
    }
  }
  .optional-box {
    width: calc(100vw - 440px);
    background-color: #f4f4f4;
    height: calc(100vh - 89px);
    position: relative;
    .page-box {
      position: absolute;
      left: 50%;
      bottom: 0;
      background: #ffffff;
      width: 100%;
      color: #b9b9b9;
      padding: 8px 12px;
      // border-top-left-radius: 4px;
      // border-top-right-radius: 4px;
      overflow: hidden;
      transform: translateX(-50%);
      -ms-transform: translateX(-50%); /* IE 9 */
      -moz-transform: translateX(-50%); /* Firefox */
      -webkit-transform: translateX(-50%); /* Safari 和 Chrome */
      -o-transform: translateX(-50%); /* Opera */
      .el-pagination {
        margin-top: 0;
      }
      > span {
        margin: 0 50px;
      }
      i {
        cursor: pointer;
      }
    }
    .scan-check-header {
      padding: 16px 24px;
      background-color: #f4f4f4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #525252;
      border-bottom: 1px solid #e7e7e7;
    }
    .optional-list-box {
      height: calc(100vh - 138px);
      overflow-y: scroll;
      padding: 12px;
      .optional-item {
        display: flex;

        margin-bottom: 24px;
        background-color: #ffffff;
        .check-question-box {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(100% - 700px);
          border-left: 1px solid #e7e7e7;
          div {
            padding: 4px 16px;
            border: 1px solid #e7e7e7;
            margin-right: 12px;
            cursor: pointer;
          }
          .on {
            background-color: #2474ed;
            border-color: #2474ed;
            color: #ffffff;
          }
        }
        .img-box {
          width: 700px;
          //   padding: 12px;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }
  .group-box-header {
    padding: 24px;
    .sacn-err-header-title {
      margin-bottom: 24px;
    }
  }
  .group-box {
    width: 440px;
    height: calc(100vh - 89px);
    border-left: 1px solid #e7e7e7;
    position: relative;
    background-color: #ffffff;
    .check-tab {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      div {
        padding-bottom: 8px;
        border-bottom: 1px solid #ffffff;
        line-height: 14px;
        cursor: pointer;
        // margin-right: 24px;
        &:last-child {
          margin-right: 0;
        }
      }
      .check-tab-on {
        color: $primary-color;
        border-bottom: 1px solid $primary-color;
      }
    }
  }
}
</style>
