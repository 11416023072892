<template>
  <div class="objectiveAnomaly">
    <div class="operation-box">
      <div class="objective-box">
        <div class="scan-check-header">
          <div>
            异常原因：客观题未涂或多涂
            <el-button type="text" @click="$parent.dialogVisible = true">
              提示
            </el-button>
          </div>
          <div>
            <el-radio-group
              v-if="GET_IS_HANDLE != 1"
              v-model="objectiveType"
              @change="checkObjectiveType()"
            >
              <el-radio :label="1">全部</el-radio>
              <el-radio :label="2">空白（未涂抹）</el-radio>
              <el-radio :label="3">多选（涂抹了多个项）</el-radio>
            </el-radio-group>
            <el-checkbox v-model="showLeftBtn">显示左侧快捷按钮</el-checkbox>
            <el-checkbox v-model="allQuestionOrder">显示全部题号</el-checkbox>
          </div>
        </div>
        <div
          v-if="objectiveQuestionList.length > 0 || listloading"
          ref="objective"
          v-loading="listloading"
          class="objective-list-box edit-scroll-style"
        >
          <div
            v-for="(v, i) in objectiveQuestionList"
            :key="i"
            class="objective-list"
          >
            <div class="objective-list-header">
              <span>学校：{{ v.schoolName }}</span>
              <span>考号：{{ v.studentExamId }}</span>
              <span>姓名：{{ v.studentName }}</span>

              <el-button type="text" @click="showPaper(v)">查看原卷</el-button>
              <el-button type="text" @click="showPaper(v, 1)"
                >查看标注卷</el-button
              >
              <el-button
                v-if="userInfo.roleId != 5"
                type="text"
                @click="openTips(v)"
                >设为缺考</el-button
              >
              <!-- <el-image :preview-src-list="[]"> 查看原试卷 </el-image> -->
            </div>
            <div class="objective-img-box">
              <div class="img-box">
                <img :src="getImg(v.url)" :style="getFunImg(v)" alt="" />
                <div
                  v-if="getShowList(v)"
                  :style="getFunIndex(v)"
                  class="left-option-box"
                >
                  <div
                    :style="getListBox(v)"
                    class="question-item"
                    :class="{ 'question-item-sh': getOptionBoxTips(v) }"
                  >
                    <div
                      v-for="(qitem, qindex) in v.exceptionList"
                      :key="qindex + 'exceptionList'"
                      class="question-option-item"
                      :style="questionList(v)"
                    >
                      <!-- {{ qitem.answer }} -->
                      <div
                        :key="
                          newTips * Math.floor(Math.random() * 100 + 1) +
                          (qindex + 1) * Math.floor(Math.random() * 100 + 1)
                        "
                        class="err-tips"
                      >
                        <template
                          v-if="qitem.answer && qitem.answer.length == 0"
                        >
                          未涂
                        </template>
                        <template v-if="setTypeOn(1, qitem)"> 多涂 </template>
                      </div>
                      <div class="question-option-item-tips">
                        {{ qitem.questionNum }}
                      </div>
                      <!-- {{ v.selectType }} -->
                      <div
                        v-for="(qLitem, qLindex) in v.exceptionList[0]
                          .optionCount"
                        :key="qLindex"
                        :style="getOptionBox(v)"
                        :class="{ on: setclassLeft(v, qindex, qLindex) }"
                        @click="editOptionLeft(v, qindex, qLindex)"
                      >
                        <!-- {{ v.selectType }} -->
                        <!-- 111111111 -->
                        <!-- {{ v.selectType }} -->
                        <template v-if="v.exceptionList[0].selectType == 3">{{
                          optionListT[qLindex]
                        }}</template>
                        <template v-else> {{ optionArr[qLindex] }}</template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="objective-question-box">
                <template>
                  <div
                    v-for="(item, index) in v.exceptionList"
                    :key="index + item.questionNum"
                    class="objective-question-list"
                    :class="{
                      'he-objective-question-list': !getQuestuintype(item),
                      'error-list': item.questionExceptionStatus > 0,
                      'hidden-box': !(
                        allQuestionOrder ||
                        (!allQuestionOrder && item.questionExceptionStatus != 0)
                      ),
                      'all-class': item.optionCount > 12,
                    }"
                  >
                    <div
                      :class="{ 'vertical-question': getQuestuintype(item) }"
                    >
                      <div class="question-order">{{ item.questionNum }}.</div>

                      <div class="option-box">
                        <template v-if="item.selectType == 3">
                          <template v-for="(m, n) in optionListT">
                            <div
                              v-if="n < item.optionCount"
                              :key="n + m"
                              class="option-list"
                              :class="{
                                on: setclass(m, item),
                              }"
                              @click="editOption(m, item)"
                            >
                              <!--                          allClass: item.optionCount > 12, -->
                              {{ m }}
                            </div>
                          </template>
                        </template>
                        <template v-else>
                          <template v-for="(m, n) in optionArr">
                            <div
                              v-if="n < item.optionCount"
                              :key="n + m"
                              class="option-list"
                              :class="{
                                on: setclass(m, item),
                                allClass: item.optionCount > 12,
                              }"
                              @click="editOption(m, item)"
                            >
                              {{ m }}
                            </div>
                          </template>
                        </template>
                      </div>
                      <div class="check-type-box">
                        <div
                          :class="{
                            'type-on': item.answer && item.answer.length == 0,
                          }"
                        >
                          未涂
                        </div>
                        <div
                          :class="{
                            'type-on': setTypeOn(1, item),
                          }"
                        >
                          多涂
                        </div>
                        <!-- {{ item.questionExceptionStatus }} -->
                        <!-- <div
                          v-for="(m, n) in typeList"
                          :key="n + m.label"
                          :class="{
                            'type-on': item.questionExceptionStatus == m.value,
                          }"
                          @click="checkType(m.value, item)"
                        >
                          {{ m.label }}
                        </div> -->
                        <!--   {
    value: 2,
    label: "未涂",
  },
  {
    value: 3,
    label: "多涂",
  }, -->
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <!-- schoolName -->
          </div>
          <el-button
            plain
            class="sub-btn"
            icon="el-icon-circle-check"
            :loading="btnLoading"
            type="primary"
            @click="submit"
          >
            提交本页
          </el-button>
        </div>
        <div v-else class="succ-box">
          <div>
            <img src="@/static/scanSheet/default-diagram.png" alt="" />
            <p>异常处理完成，老师您辛苦了!</p>
          </div>
        </div>
        <div
          v-if="indexQuestion.number > 0 && GET_IS_HANDLE != 0"
          class="page-box"
        >
          <el-pagination
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotal"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        <div v-if="GET_IS_HANDLE == 0" class="page-box">
          共{{ indexQuestion.number }}条
          <el-select
            v-model="pageSize"
            placeholder="请选择"
            @change="handleSizeChange(pageSize)"
          >
            <el-option label="10条/页" :value="10"> </el-option>
            <el-option label="20条/页" :value="20"> </el-option>
            <el-option label="40条/页" :value="40"> </el-option>
            <el-option label="60条/页" :value="60"> </el-option>
            <el-option label="100条/页" :value="100"> </el-option>
          </el-select>
        </div>
      </div>
      <div class="scan-err">
        <div class="sacn-err-header">
          <!-- <div class="sacn-err-header-title"> -->
          <!-- {{ $parent.sheetInfomation.schoolName }} -->
          <!-- <el-button type="text" @click="showScan">重新识别</el-button> -->
          <!-- </div> -->
          <div class="check-item-box-header">
            <div class="label">答题卡数据来源：</div>

            <el-select
              v-model="scanPointId"
              placeholder="请选择"
              filterable
              @change="getSchoolList(1)"
            >
              <el-option
                v-for="item in scanItem"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="schoolId"
              placeholder="请选择"
              filterable
              @change="checkItem()"
            >
              <el-option
                v-for="item in scanSchoolItem"
                :key="item.schoolId"
                :label="item.schoolName"
                :value="item.schoolId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="check-tab">
            <!-- 如果是考号填涂异常 之后的 才会有已处理 -->
            <!-- {{ GET_ABNORMAL_LIST }} -->

            <div
              :class="{ 'check-tab-on': GET_IS_HANDLE == 2 }"
              @click="checkHander(2)"
            >
              全部({{ getNumsData.allNumber }})
            </div>
            <div
              :class="{ 'check-tab-on': GET_IS_HANDLE == 0 }"
              @click="checkHander(0)"
            >
              待处理({{ getNumsData.number }})
            </div>
            <div
              :class="{ 'check-tab-on': GET_IS_HANDLE == 1 }"
              @click="checkHander(1)"
            >
              已处理({{ getNumsData.handnumber }})
            </div>
          </div>
        </div>
        <div class="question-list-box edit-scroll-style">
          <!--  -->
          <div
            v-for="(v, i) in basequestionList"
            :key="i"
            class="paper-item absent-list"
            :class="{
              on: indexQuestion.questionGroupId == v.questionGroupId,
            }"
            @click="checkIndexPaper(v)"
          >
            <!-- {{ indexQuestion.questionGroup }} -->
            <p><i class="el-icon-caret-left"></i>{{ v.questionNumGroup }}</p>
            <span>{{ v.number }}</span>
          </div>
        </div>

        <!-- <div class="btn-box">
          <el-button
            plain
            class="btn-style"
            icon="el-icon-circle-check"
            :loading="btnLoading"
            type="primary"
            @click="submit"
          >
            提交本页
          </el-button>
        </div> -->
      </div>
    </div>
    <showImgList ref="showImgList" :src-list="imgUrl"></showImgList>
  </div>
</template>

<script>
import { optionItem, optionListT } from "@/core/util/constdata";
const typeList = [
  {
    value: 2,
    label: "未涂",
  },
  {
    value: 3,
    label: "多涂",
  },
];
import { mapGetters } from "vuex";
import { imgTime } from "@/core/util/util";
import {
  submitObjectivequestionexceptions,
  basequestionexceptions,
  objectivequestionexceptions,
  scanrecordAbsent,
  appointErrortypeNums,
} from "@/core/api/exam/examScanSheet";
import {
  getexamscanpoint,
  getschoollist,
  // answersheetScanrecord,
  // scanrecordunionAnswersheet,
  // scanrecordbycondition,
} from "@/core/api/exam/examUnionScan";
import showImgList from "@/components/showImgList";
import { getStore } from "@/core/util/store";
export default {
  name: "ObjectiveAnomaly",
  components: {
    showImgList,
  },
  data() {
    return {
      btnLoading: false,
      pageIndex: 1,
      pageSize: 20,
      pageTotal: 1,
      size: 6,
      total: 0,
      optionArr: optionItem,
      optionListT: optionListT,
      typeList: typeList,
      allQuestionOrder: true,
      showLeftBtn: true,
      indexQuestion: {},
      getNumsData: {},
      basequestionList: [],
      objectiveQuestionList: [],
      newTips: 1,
      listloading: false,
      objectiveType: 1,
      imgUrl: [],
      scanItem: [],
      scanSchoolItem: [],
      scanPointId: 0,
      schoolId: 0,
      userInfo: {},
    };
  },
  computed: {
    // GET_ABNORMAL_LIST GET_INDEX_SCAN_TYPE
    ...mapGetters([
      "GET_INDEX_SCAN_TYPE",
      "GET_IS_HANDLE",
      "GET_ABNORMAL_LIST",
      "GET_CURRENT",
      "GET_SCAN_ERR_LIST",
      "GET_INDEX_SCAN_ERR",
    ]),
  },
  async created() {
    this.userInfo = getStore({ name: "userInfo" });
    await this.getexamscanpoint();
    this.getSchoolList();
  },
  methods: {
    async getSchoolList(type) {
      let data = { examId: this.$route.query.examId, id: this.scanPointId };
      if (type) {
        this.getNums();
        this.schoolId = 0;
      }
      await getschoollist(data).then((res) => {
        this.scanSchoolItem = res.data.data;
        if (type) {
          this.basequestionexceptions();
        }
      });
    },
    checkItem() {
      this.getNums();
      this.basequestionexceptions();
    },
    async getexamscanpoint() {
      let data = {
        examId: this.$route.query.examId,
        examPaperId: this.$route.query.examPaperId,
      };
      // 详情也要看管理员扫描点
      // data.isBatch = 1;
      await getexamscanpoint(data).then((res) => {
        this.scanItem = res.data.data;
        this.scanPointId = res.data.data[0].id;
      });
    },
    getNums() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        errTypeId: this.GET_INDEX_SCAN_TYPE,
        schoolId: this.schoolId,
        examScanPointId: this.scanPointId,
      };

      appointErrortypeNums(data).then((res) => {
        this.getNumsData = {
          handnumber: 0,
          number: 0,
          allnumber: 0,
        };

        res.data.data.map((item) => {
          if (item.errorTypeId == this.GET_INDEX_SCAN_TYPE) {
            if (item.isHandle == 1) {
              this.getNumsData.handnumber = item.number;
            } else {
              this.getNumsData.number = item.number;
            }
          }
          if (item.errorTypeId == 100) {
            this.getNumsData.allNumber = item.number;
          }
        });
      });
    },
    openTips(item) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        message: h("p", null, [
          h("span", null, "当前正在将 "),
          h("span", { style: "color: red" }, `${item.studentName}`),
          h("span", null, " 答题卡设为缺考，是否继续？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            this.scanrecordAbsent(item, instance, done);
            // setTimeout(() => {
            //   done();
            //   setTimeout(() => {
            //     instance.confirmButtonLoading = false;
            //   }, 300);
            // }, 3000);
          } else {
            done();
          }
        },
      })
        .then(() => {})
        .catch(() => {});
    },
    scanrecordAbsent(item, instance, done) {
      let data = {
        scanRecordId: item.scanRecordId,
        absent: 1,
      };

      scanrecordAbsent(data)
        .then(() => {
          this.$message({
            message: "设置成功",
            type: "success",
            showClose: true,
          });
          this.objectiveQuestionList = this.objectiveQuestionList.filter(
            (index) => item.scanRecordId != index.scanRecordId
          );
          // this.$refs.objective.scrollTo(0, 0);
          done();
          instance.confirmButtonLoading = false;
          // this.$parent.getErrorList();
        })
        .catch(() => {
          instance.confirmButtonText = "确定";
          instance.confirmButtonLoading = false;
        });
    },
    objectivequestionexceptions() {
      let data = {
        current: this.pageIndex,
        size: this.pageSize,
        questionGroup: this.indexQuestion.questionGroupId,
        examPaperId: this.$route.query.examPaperId,
        isHandle: this.GET_IS_HANDLE,
        type: this.objectiveType,
        schoolId: this.schoolId,
        examScanPointId: this.scanPointId,
      };
      if (!this.indexQuestion.questionGroupId) {
        return;
      }
      this.listloading = true;
      objectivequestionexceptions(data)
        .then((res) => {
          res.data.data.records.map((item) => {
            item.exceptionList.map((index) => {
              if (index.selectType == 3) {
                if (
                  index.answerText.indexOf("A") != -1 ||
                  index.answerText.indexOf("B") != -1
                ) {
                  index.answerText = "";
                }
              }
            });
          });
          this.objectiveQuestionList = res.data.data.records;
          // console.log(this.objectiveQuestionList);
          setTimeout(() => {
            this.newTips = new Date().getTime();
          }, 200);

          // this.objectiveQuestionList = [...this.objectiveQuestionList];
          this.pageTotal = res.data.data.total;
          this.listloading = false;
        })
        .catch(() => {
          this.listloading = false;
        });
    },
    checkIndexPaper(v) {
      this.pageIndex = 1;
      this.indexQuestion = v;
      this.objectiveQuestionList = [];
      this.objectivequestionexceptions();
    },
    checkObjectiveType() {
      this.pageIndex = 1;
      this.objectiveQuestionList = [];
      this.$parent.$refs.scanErrTable.errortypeNums(this.objectiveType);
      this.basequestionexceptions();
      // this.objectivequestionexceptions();
    },
    basequestionexceptions() {
      let data = {
        errType: 14,
        examPaperId: this.$route.query.examPaperId,
        isHandle: this.GET_IS_HANDLE,
        type: this.objectiveType,
        schoolId: this.schoolId,
        examScanPointId: this.scanPointId,
      };

      basequestionexceptions(data)
        .then((res) => {
          this.objectiveQuestionList = [];
          if (res.data.data.length > 0) {
            this.basequestionList = res.data.data;
            let questionGroupIdArr = this.basequestionList.map(
              (item) => item.questionGroupId
            );
            if (
              questionGroupIdArr.indexOf(this.indexQuestion.questionGroupId) ==
              -1
            ) {
              this.indexQuestion = res.data.data[0];
            }
            // console.log();
            // 添加判断  如果再最后一页  就调整下页码
            if (this.pageSize * this.pageIndex > this.indexQuestion.number) {
              let index = parseInt(this.indexQuestion.number / this.pageSize);
              this.pageIndex = index ? index : 1;
            }

            // number
            this.objectivequestionexceptions();
          } else {
            this.basequestionList = [];
            this.indexQuestion = {};
          }
        })
        .catch(() => {
          this.basequestionList = [];
          this.indexQuestion = {};
        });
    },
    // 修改异常类型
    checkType(type, item) {
      if (item.questionExceptionStatus == type) {
        item.questionExceptionStatus = 1;
      } else {
        item.questionExceptionStatus = type;
      }
      this.$nextTick(() => {
        this.objectiveQuestionList = [...this.objectiveQuestionList];
      });
    },
    setTypeOn(option, item) {
      let type = false;
      if (!item.answer) {
        item.answer = [];
        if (item.answerText) {
          item.answer = item.answerText.split("");
        }
        // return;
      }
      // console.log(item.answer);
      // 如果是单选
      if (item.selectType == 1 || item.selectType == 3) {
        // 多涂
        if (option) {
          if (item.answer.length > 1) {
            type = true;
          }
        } else {
          // 未涂
          if (item.answer.length == 1) {
            type = true;
          }
        }
      } else {
        // 未涂
        if (!option) {
          if (item.answer.length == 1) {
            type = true;
          }
        }
      }
      return type;
      // console.log(item);
      // console.log(type);
    },
    setclassLeft(item, index, index1) {
      //  item 当前试题组 index当前小题位置 index1 当前选项位置
      // console.log(item);
      // console.log(index);
      // console.log(index1);
      // let option = this.optionArr[index1];
      let option;
      if (item.exceptionList[0].selectType == 3) {
        option = this.optionListT[index1];
      } else {
        option = this.optionArr[index1];
      }
      let type = false;
      if (!item.exceptionList[index].answer) {
        item.exceptionList[index].answer = [];
        if (item.exceptionList[index].answerText) {
          item.exceptionList[index].answer =
            item.exceptionList[index].answerText.split("");
        }
      }
      if (item.exceptionList[index].answer.indexOf(option) != -1) {
        type = true;
      }
      return type;
    },
    // 左边修改选项
    editOptionLeft(item, index, index1) {
      //  item 当前试题组 index当前小题位置 index1 当前选项位置
      // console.log(item.exceptionList);
      // console.log(index);
      // console.log(index1);
      let option;
      if (item.exceptionList[0].selectType == 3) {
        option = this.optionListT[index1];
      } else {
        option = this.optionArr[index1];
      }
      // let option = this.optionArr[index1];
      if (item.exceptionList[index].answer.indexOf(option) == -1) {
        item.exceptionList[index].answer.push(option);
      } else {
        item.exceptionList[index].answer.splice(
          item.exceptionList[index].answer.indexOf(option),
          1
        );
      }
      this.$nextTick(() => {
        this.objectiveQuestionList = [...this.objectiveQuestionList];
      });
    },
    // 修改选项
    editOption(option, item) {
      // console.log(item.answer.indexOf(option));
      // if (item.selectType == 1) {
      //   if (item.answer.indexOf(option) == -1) {
      //     item.answer = [option];
      //   } else {
      //     item.answer = [];
      //   }
      // } else {
      if (item.answer.indexOf(option) == -1) {
        item.answer.push(option);
      } else {
        item.answer.splice(item.answer.indexOf(option), 1);
      }
      // }
      this.$nextTick(() => {
        this.objectiveQuestionList = [...this.objectiveQuestionList];
      });
      // console.log();
    },

    getImg: imgTime,

    getShowList(item) {
      let answerArea = "";
      let type = false;
      if (
        item.exceptionList &&
        item.exceptionList[0] &&
        item.exceptionList[0].answerArea
      ) {
        answerArea = JSON.parse(item.exceptionList[0].answerArea);
        let { calculate } = answerArea;
        // console.log(calculate);
        if (this.showLeftBtn && calculate) {
          type = true;
        }
      }

      return type;
    },
    // 题号问题
    getOptionBoxTips(item) {
      let answerArea = "";
      if (
        !item.exceptionList ||
        !item.exceptionList[0] ||
        !item.exceptionList[0].answerArea
      ) {
        return;
      }
      if (item.exceptionList[0].answerArea) {
        answerArea = JSON.parse(item.exceptionList[0].answerArea);
      }
      let { optionsArrangementMode } = answerArea;

      return optionsArrangementMode == 2 ? true : false;
    },
    // 竖向选择题问题
    questionList(item) {
      let answerArea = "";
      if (
        !item.exceptionList ||
        !item.exceptionList[0] ||
        !item.exceptionList[0].answerArea
      ) {
        return;
      }
      if (item.exceptionList[0].answerArea) {
        answerArea = JSON.parse(item.exceptionList[0].answerArea);
      }
      let { optionsArrangementMode, calculate, optionsLength, source } =
        answerArea;
      if (optionsArrangementMode == 1) {
        return;
      }
      if (!calculate) {
        return;
      }
      let allWidth = source.width;
      let proportion = 1;
      if (allWidth > 700) {
        proportion = allWidth / 700;
      }
      let width = `width:${calculate.sizeWidth / proportion}px;`;
      let height = `height:${
        (calculate.sizeHeight * optionsLength +
          calculate.sizeBottom * (optionsLength - 1)) /
        proportion
      }px;`;

      return width + height + "flex-wrap: wrap;flex-direction: column;";
      // + "flex-direction: column;"
    },
    // 获取选项大小
    getOptionBox(item) {
      let answerArea = "";
      if (
        !item.exceptionList ||
        !item.exceptionList[0] ||
        !item.exceptionList[0].answerArea
      ) {
        return;
      }
      if (item.exceptionList[0].answerArea) {
        answerArea = JSON.parse(item.exceptionList[0].answerArea);
      }
      let { calculate, source, optionsArrangementMode, optionsLength } =
        answerArea;
      if (!calculate) {
        return;
      }
      let allWidth = source.width + source.width * (1 / optionsLength);
      let proportion = 1;
      if (allWidth > 700) {
        proportion = allWidth / 700;
      }
      if (optionsArrangementMode == 2) {
        if (source.width > 700) {
          proportion = allWidth / 700;
        }
      }
      // console.log(calculate);
      let width = `width:${calculate.sizeWidth / proportion}px;`;
      let height = `height:${calculate.sizeHeight / proportion}px;line-height:${
        calculate.sizeHeight / proportion
      }px;font-size:${calculate.sizeHeight / proportion}px;`;
      return width + height;
    },
    // 获取选择题组的大小
    getListBox(item) {
      let answerArea = "";
      if (
        !item.exceptionList ||
        !item.exceptionList[0] ||
        !item.exceptionList[0].answerArea
      ) {
        return;
      }
      if (item.exceptionList[0].answerArea) {
        answerArea = JSON.parse(item.exceptionList[0].answerArea);
      }
      let { calculate, optionsArrangementMode, source, optionsLength } =
        answerArea;

      if (!calculate) {
        return;
      }
      let allWidth = source.width + source.width * (1 / optionsLength);
      let proportion = 1;
      if (allWidth > 700) {
        proportion = allWidth / 700;
      }
      let width = `width:${
        (calculate.sizeWidth * optionsLength +
          calculate.sizeRight * (optionsLength - 1)) /
        proportion
      }px;`;
      let height = `height:${
        (calculate.sizeHeight * item.exceptionList.length +
          calculate.sizeBottom * (item.exceptionList.length - 1)) /
        proportion
      }px;`;
      // 竖向的情况
      if (optionsArrangementMode == 2) {
        allWidth = source.width;
        if (allWidth > 700) {
          proportion = allWidth / 700;
        }
        width = `width:${
          (calculate.sizeWidth * item.exceptionList.length +
            calculate.sizeRight * (item.exceptionList.length - 1)) /
          proportion
        }px;`;
        height = `height:${
          (calculate.sizeHeight * optionsLength +
            calculate.sizeBottom * (optionsLength - 1)) /
          proportion
        }px;`;
      }
      return width + height;
    },
    // 获取当前最大盒子的位置
    getFunIndex(item) {
      let answerArea = "";
      if (
        !item.exceptionList ||
        !item.exceptionList[0] ||
        !item.exceptionList[0].answerArea
      ) {
        return;
      }
      if (item.exceptionList[0].answerArea) {
        answerArea = JSON.parse(item.exceptionList[0].answerArea);
      }
      // 获取大框的位置
      let {
        optionsLength,
        source,
        optionsArrangementMode,
        leftTopWidthSize,
        leftTopHeightSize,
      } = answerArea;
      // 设置比例  如果图片大于700  处理下
      let allWidth = source.width + source.width * (1 / optionsLength);
      let proportion = 1;
      if (allWidth > 700) {
        proportion = allWidth / 700;
      }
      let width = `width:${
        (source.width + source.width * (1 / optionsLength)) / proportion
      }px;`;
      let height = `height:${source.height / proportion}px;`;
      let paddingLeft = `padding-left:${
        (leftTopWidthSize + source.width * (1 / optionsLength)) / proportion
      }px;`;
      let paddingTop = `padding-top:${leftTopHeightSize}px;`;
      if (optionsArrangementMode == 2) {
        allWidth = source.width;
        if (allWidth > 700) {
          proportion = allWidth / 700;
        }
        width = `width:${source.width / proportion}px;`;
        height = `height:${
          (source.height + source.width * (1 / optionsLength)) / proportion
        }px;`;
        paddingLeft = `padding-left:${leftTopWidthSize}px;`;
        paddingTop = `padding-top:${
          (leftTopHeightSize + source.width * (1 / optionsLength)) / proportion
        }px;`;
      }
      return width + height + paddingLeft + paddingTop;
    },
    // 获取img的位置
    getFunImg(item) {
      let answerArea = "";
      if (
        !item.exceptionList ||
        !item.exceptionList[0] ||
        !item.exceptionList[0].answerArea
      ) {
        return;
      }
      if (item.exceptionList[0].answerArea) {
        answerArea = JSON.parse(item.exceptionList[0].answerArea);
      }
      // 获取大框的位置
      let { optionsLength, source, optionsArrangementMode } = answerArea;
      // 设置比例  如果图片大于700  处理下
      let allWidth = source.width + source.width * (1 / optionsLength);
      let proportion = 1;
      if (allWidth > 700) {
        proportion = allWidth / 700;
      }
      let width = `width:${
        (source.width + source.width * (1 / optionsLength)) / proportion
      }px;`;
      let height = `height:${source.height / proportion}px;`;
      if (optionsArrangementMode == 2) {
        allWidth = source.width;
        if (allWidth > 700) {
          proportion = allWidth / 700;
        }
        width = `width:${source.width / proportion}px;`;
        height = `height:${
          (source.height + source.width * (1 / optionsLength)) / proportion
        }px;`;
      }
      return width + height;
    },
    getQuestuintype(item) {
      let optionsArrangementMode = 1;
      let answerArea = item.answerArea;
      if (answerArea) {
        answerArea = JSON.parse(answerArea);
        optionsArrangementMode = answerArea.optionsArrangementMode;
      }

      return optionsArrangementMode == 1 ? false : true;
    },
    // 切换size
    handleSizeChange(val) {
      this.pageSize = val;
      this.$refs.objective.scrollTo(0, 0);
      this.objectiveQuestionList = [];
      this.objectivequestionexceptions();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.$refs.objective.scrollTo(0, 0);
      this.objectiveQuestionList = [];
      this.objectivequestionexceptions();
    },
    // 查看原卷
    showPaper(val, type) {
      if (type) {
        if (!val.markUrls) {
          this.$message({
            message: "当前学生试卷信息为空",
            type: "warning",
            showClose: true,
          });
          return;
        }
        this.imgUrl = val.markUrls.split("，");
        this.$refs.showImgList.showImg = true;
      } else {
        if (!val.urls) {
          this.$message({
            message: "当前学生试卷信息为空",
            type: "warning",
            showClose: true,
          });
          return;
        }
        this.imgUrl = val.urls.split("，");
        this.$refs.showImgList.showImg = true;
      }
    },

    // 显示json重新识别
    showScan() {
      this.$refs.scanRecognition.init();
    },
    // 切换处理状态
    checkHander(val) {
      this.indexQuestion = {};
      this.$store.commit("SET_IS_HANDLE", val);
      this.$parent.getErrorList();
    },
    setclass(option, item) {
      let type = false;
      if (!item.answer) {
        item.answer = [];
        if (item.answerText) {
          item.answer = item.answerText.split("");
        }
      }
      if (item.answer.indexOf(option) != -1) {
        type = true;
      }
      return type;
    },
    submit() {
      let submitArr = [];
      this.objectiveQuestionList.forEach((item) => {
        item.exceptionList.forEach((index) => {
          let answer = index.answer.sort().join("");
          //  ? index.answer.join("") : index.answerText
          submitArr.push({
            id: index.id,
            questionExceptionStatus:
              index.answer.sort().join("") == index.answerText
                ? index.questionExceptionStatus
                : 1,
            answerText: answer,
          });
        });
      });
      this.btnLoading = true;
      submitObjectivequestionexceptions(submitArr)
        .then(() => {
          this.$message({
            message: "提交成功",
            type: "success",
            showClose: true,
          });
          this.$refs.objective.scrollTo(0, 0);
          this.btnLoading = false;
          this.$nextTick(() => {
            this.$parent.getErrorList();
          });
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.objectiveAnomaly {
  .absent-list {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    > span {
      position: absolute;
      right: 24px;
      top: 15px;
    }
  }
  .question-list-box {
    height: calc(100vh - 210px);
    &::-webkit-scrollbar-thumb {
      background-color: $primary-color;
    }
  }

  .sub-btn {
    display: block;
    margin: 24px auto;
  }
  .operation-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .paper-item {
    padding: 15px 0 15px 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    p {
      margin-bottom: 0;
      width: 300px;
    }
    ::v-deep .el-checkbox-group {
      position: absolute;
      right: 24px;
      top: 15px;
      .el-checkbox__label {
        display: none;
      }
    }
    .el-icon-caret-left {
      position: absolute;
      opacity: 0;
      font-size: 18px;
    }
  }
  .on {
    background: #f2f5f8;

    .el-icon-caret-left {
      // position: absolute;
      opacity: 1;
      left: 20px;
      top: 16px;
    }
  }
  .objective-box {
    width: calc(100vw - 430px);
    position: relative;
    .page-box {
      position: absolute;
      left: 50%;
      bottom: 0;
      background: #ffffff;
      width: 100%;
      color: #b9b9b9;
      padding: 8px 12px;
      // border-top-left-radius: 4px;
      // border-top-right-radius: 4px;
      overflow: hidden;
      transform: translateX(-50%);
      -ms-transform: translateX(-50%); /* IE 9 */
      -moz-transform: translateX(-50%); /* Firefox */
      -webkit-transform: translateX(-50%); /* Safari 和 Chrome */
      -o-transform: translateX(-50%); /* Opera */
      text-align: right;
      .el-select {
        width: 110px;
      }
      .el-pagination {
        margin-top: 0;
      }
      > span {
        margin: 0 50px;
      }
      i {
        cursor: pointer;
      }
    }
    .succ-box {
      height: calc(100vh - 141px);
      width: calc(100% - 94px);
      background: #f3f3f3;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      flex-wrap: wrap;
      p {
        width: 100%;
        color: #525252;
        margin-bottom: 0;
        margin-top: 12px;
      }
    }
    .objective-list-box {
      background: #f3f3f3;
      height: calc(100vh - 141px);
      padding: 24px 24px 48px;
      .objective-list {
        padding: 24px;
        background-color: #ffffff;
        margin-bottom: 24px;
        .objective-img-box {
          border: 1px solid #d8d8d8;
          display: flex;
          margin-top: 24px;
          .objective-question-box {
            flex-wrap: wrap;
            margin-top: 24px;
            width: calc(100% - 700px);

            .error-list {
              background-color: #fddddd;
            }
            .hidden-box {
              opacity: 0;
              visibility: hidden;
            }
            .he-objective-question-list {
              min-width: 54%;
            }
            // .edit-scroll-style {
            //   overflow-x: scroll;
            // }
            .all-class {
              > div {
                // flex-wrap: wrap;
                .option-box {
                  flex-wrap: wrap;
                  .option-list {
                    width: 20px;
                    margin-left: 2px;
                    margin-bottom: 2px;
                  }
                }
              }
            }
            .objective-question-list {
              // width: 100%;

              max-width: 100%;

              .question-order {
                min-width: 30px;
                text-align: right;
              }

              > div {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 3px 24px;
              }
              .option-box {
                display: flex;
                justify-content: center;
                align-items: center;
                > div {
                  width: 37px;
                  height: 22px;
                  text-align: center;
                  line-height: 22px;
                  color: #090b0a;
                  background-color: #ffffff;
                  border: 1px solid #d8d8d8;
                  margin-left: 10px;
                  cursor: pointer;
                }
                // 选项太多情况下
                .allClass {
                  // margin-left: 2px;
                  // width: 18px;
                }
                .on {
                  background-color: $primary-color;
                  border-color: $primary-color;
                  color: #ffffff;
                }
              }
              .check-type-box {
                display: flex;
                justify-content: center;
                align-items: center;
                .type-on {
                  background-color: red;
                  border-color: red;
                  color: #ffffff;
                }
                div {
                  cursor: pointer;
                  width: 60px;
                  height: 22px;
                  background: #ffffff;
                  border-radius: 2px;
                  border: 1px solid #d8d8d8;
                  margin-left: 10px;
                  text-align: center;
                }
              }
              .vertical-question {
                width: 65px;
                flex-wrap: wrap;
                padding: 0;
                padding-top: 10px;
                .question-order {
                  margin-bottom: 10px;
                  white-space: nowrap;
                }

                .option-box {
                  flex-wrap: wrap;
                  > div {
                    margin-left: 0;
                    margin-bottom: 10px;
                    width: 60px;
                  }
                }
                .check-type-box {
                  flex-wrap: wrap;
                  > div {
                    margin-bottom: 10px;
                    margin-left: 0;
                  }
                }
              }
            }
          }
          > div {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 18px 0px;
            // width: 50%;
          }
          .img-box {
            border-right: 1px solid #d8d8d8;
            position: relative;
            width: 700px;
            .left-option-box {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              -ms-transform: translateX(-50%); /* IE 9 */
              -moz-transform: translateX(-50%); /* Firefox */
              -webkit-transform: translateX(-50%); /* Safari 和 Chrome */
              -o-transform: translateX(-50%); /* Opera */
              .question-item {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                flex-direction: column;
                .question-option-item {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  text-align: center;
                  position: relative;
                  .err-tips {
                    position: absolute;
                    left: -100px;
                    border: none;
                    opacity: 1;
                    color: red;
                  }
                  div.question-option-item-tips {
                    position: absolute;
                    left: -50px;
                    border: none;
                    opacity: 1;
                    color: #333;
                    font-weight: 100;
                    color: #099;
                    z-index: 0;
                  }
                  div {
                    color: rgba(255, 255, 255, 0);
                    border: 2px solid #099;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    user-select: none;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 0.5;
                    z-index: 1;
                  }
                  .on {
                    // background-color: rgba(0, 0, 255, 0.4);
                    // background-color: rgb(245 0 0 / 40%);
                    background-color: rgba(0, 0, 0, 0);
                    color: red;
                  }
                }
              }
              .question-item-sh {
                flex-direction: unset;
                .question-option-item {
                  .err-tips {
                    left: -4px;
                    top: -24px;
                    width: 42px;
                  }
                  .question-option-item-tips {
                    left: -4px !important;
                    top: -50px;
                    width: 60px;
                  }
                }
              }
            }
            img {
              // max-width: 100%;
            }
            // text-align: center;
            // img {
            //   display: inline-block;
            // }
          }
        }
        .objective-list-header {
          span {
            margin-right: 24px;
          }

          color: #aaaaaa;
        }
      }
    }
    .scan-check-header {
      padding: 16px 24px;
      background-color: #f4f4f4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #525252;
      border-bottom: 1px solid #e7e7e7;
      .el-radio-group {
        // margin-right: 18px;
        .el-radio {
          margin-right: 18px;
          margin-bottom: 0;
        }
      }
      .el-checkbox {
        margin-bottom: 0;
      }
      .img-nums {
        width: 100px;
        height: 28px;
        line-height: 28px;
        margin-left: 24px;
        ::v-deep .el-input__inner {
          height: 28px;
          line-height: 28px;
        }
      }
    }
  }
  .scan-err {
    width: 440px;
    height: calc(100vh - 89px);
    border-left: 1px solid #e7e7e7;
    position: relative;
    background-color: #ffffff;
    .btn-box {
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #e7e7e7;
      padding: 24px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: #ffffff;
      .el-button {
        width: 184px;
      }
      .btn-style {
        width: 100% !important;
        margin-top: 24px;
      }
      .el-button + .el-button {
        margin-left: 0;
      }
      .input-width {
        width: 100%;
        margin-bottom: 24px;
        .el-button {
          width: 60px;
        }
      }
    }
    .sacn-err-header {
      padding: 24px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-bottom: 1px solid #e7e7e7;
      .check-tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        div {
          padding-bottom: 8px;
          border-bottom: 1px solid #ffffff;
          line-height: 14px;
          cursor: pointer;
          // margin-right: 24px;
          &:last-child {
            margin-right: 0;
          }
        }
        .check-tab-on {
          color: $primary-color;
          border-bottom: 1px solid $primary-color;
        }
      }
      .sacn-err-header-title {
        margin-bottom: 24px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      span {
        border-bottom: 1px solid #e7e7e7;
        cursor: pointer;
      }
    }
  }
}
</style>
